<template>
  <div class="filter-box" v-show="inputValue" @click="onClose">
    <div
      :class="{
        'filter-body': true,
        show: show,
      }"
      @click.stop="() => false"
      @touchstart.stop="() => false"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Boolean
    },
    style: {
      type: String
    }
  },
  setup (props, { emit }) {
    const inputValue = ref(props.value)
    const show = ref(false)

    watch(props, (e) => {
      inputValue.value = e.value

      setTimeout(() => {
        show.value = e.value
      }, 10)
    })

    const onClose = () => {
      inputValue.value = false
      emit('update:value', false)
    }

    return {
      inputValue,
      show,
      onClose
    }
  }
})
</script>

<style lang="less" scoped>
.filter-box {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  .filter-body {
    position: absolute;
    top: 0;
    right: -100%;
    width: 50%;
    height: 100%;
    background: #fff;
    border-radius: 5px 0 0 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    transition: all .2s ease;
    &.show{
      right: 0;
    }
  }
}
</style>
