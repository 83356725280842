<template>
  <div class="content">
    <a-button
      type="primary"
      shape="circle"
      class="filter-btn"
      @click="onShowFilter"
    >
      <template #icon><FilterOutlined /></template>
    </a-button>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="list"
      >
        <div class="item" v-for="(v, k) in list" :key="k">
          <div class="top">
            <span>{{ v.date }}</span>
          </div>
          <a-row>
            <a-col :span="24">
              <a-row>
                <a-col :span="6">
                  <div class="label-title">姓名</div>
                </a-col>
                <a-col :span="16">
                  <div class="value" style="line-height: 32px">
                    {{ v.customerName }}
                    <span v-if="v.customerSex == 1" class="sex man">
                      <ManOutlined />
                    </span>
                    <span v-else-if="v.customerSex == 2" class="sex feman">
                      <WomanOutlined />
                    </span>
                    <span v-else class="sex">
                      <ManOutlined />
                    </span>
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="12">
              <e-info
                :labelCol="12"
                :valueCol="12"
                title="手机号码"
                :value="v.customerMobile"
              />
            </a-col>
            <a-col :span="12">
              <e-info
                :labelCol="16"
                :valueCol="6"
                title="预约次数"
                :value="v.bookTimes"
              />
            </a-col>
            <a-col :span="12">
              <e-info
                :labelCol="12"
                :valueCol="12"
                title="最近消费"
                :value="$filters.formatDate(v.lastCostTime, 'YYYY-MM-DD') || '暂无消费'"
              />
            </a-col>
            <a-col :span="12">
              <e-info
                :labelCol="16"
                :valueCol="6"
                title="成交次数"
                :value="v.successTimes"
              />
            </a-col>
          </a-row>
        </div>
      </van-list>
    </van-pull-refresh>

    <Footer />

    <FilterBox ref="filterBox" v-model:value="show">
      <div class="filter-item">
        <div class="item-title">姓名</div>
        <s-input
          title="姓名"
          :showTitle="false"
          :labelCol="0"
          :valueCol="24"
          v-model:value="queryParams.Name"
        />

        <div class="item-title">手机号</div>
        <s-input
          title="手机号"
          :showTitle="false"
          :labelCol="0"
          :valueCol="24"
          v-model:value="queryParams.Mobile"
        />

        <div class="item-title">性别</div>
        <div class="item-form">
          <a-radio-group v-model:value="queryParams.Sex">
            <a-radio v-for="(v, k) in options" :key="k" :value="v.value">{{
              v.label
            }}</a-radio>
          </a-radio-group>
        </div>

        <div class="item-title">是否挂账</div>
        <div class="item-form">
          <a-radio-group v-model:value="queryParams.AllowCredit">
            <a-radio :value="true">是</a-radio>
            <a-radio :value="false">否</a-radio>
          </a-radio-group>
        </div>

        <div class="btns">
          <div class="btn ok" @click="onOkFilter">确定</div>
          <div class="btn cancle" @click="onCloseFilter">取消</div>
          <div class="btn reset" @click="onResetFilter">重置</div>
        </div>
      </div>
    </FilterBox>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {
  FilterOutlined,
  ManOutlined,
  WomanOutlined
} from '@ant-design/icons-vue'
import Footer from './Footer'
import { List, PullRefresh } from 'vant'

import 'vant/es/list/style'
import 'vant/es/pull-refresh/style'
import { FinAccountClass } from '@/apis/fin'
import defaultConfig from '@/config/default.config'
import FilterBox from '@/components/vant-form/FilterBox'

const api = new FinAccountClass()
export default defineComponent({
  components: {
    Footer,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    FilterOutlined,
    FilterBox,
    ManOutlined,
    WomanOutlined
  },
  setup () {
    const queryParams = ref({
      Limit: 20,
      Type: 0,
      OperatorId: ''
    })
    const paginate = ref({
      total: 0,
      page: 1
    })
    const store = ref()
    const list = ref([])
    const show = ref(false)
    const loading = ref(false)
    const finished = ref(false)
    const refreshing = ref(false)
    const activeTab = ref(-1)

    const onLoad = () => {
      console.log('加载', paginate.value.total, paginate.value.page)
      if (
        paginate.value.page * queryParams.value.Limit >= paginate.value.total ||
        paginate.value.total <= queryParams.value.Limit
      ) {
        finished.value = true
      } else {
        getDataList(++paginate.value.page)
      }
    }

    const getDataList = (page) => {
      loading.value = true
      if (page === 1) {
        list.value = []
      }

      paginate.value.page = page
      const q = { ...queryParams.value }

      q.Offset = (page - 1) * q.Limit
      api.gets(q).then((resp) => {
        list.value.push(...resp.data)

        paginate.value.total = resp.total

        loading.value = false
        refreshing.value = false

        if (page >= resp.total) {
          finished.value = true
        } else {
          finished.value = false
        }
      })
    }

    const onRefresh = () => {
      refreshing.value = true
      getDataList(1)
    }

    const onType = (e) => {
      if (e.name === -1) {
        delete queryParams.value.OrderType
      } else {
        queryParams.value.OrderType = e.name
      }

      getDataList(1)
    }

    const onShowFilter = () => {
      show.value = true
    }

    const onCloseFilter = () => {
      show.value = false
    }

    const onOkFilter = () => {
      show.value = false
      getDataList(1)
    }

    getDataList(1)

    return {
      queryParams,
      paginate,
      list,
      loading,
      finished,
      refreshing,
      store,
      show,
      activeTab,
      options: [
        {
          label: '先生',
          value: 1
        },
        {
          label: '女士',
          value: 2
        }
      ],
      onLoad,
      onRefresh,
      onType,
      onShowFilter,
      onCloseFilter,
      onOkFilter
    }
  },

  created () {
    this.getStoreInfo()
  },
  methods: {
    async getStoreInfo () {
      const store = this.$store.state.user.storeInfo.id
        ? this.$store.state.user.storeInfo
        : await this.$store.dispatch('GetStoreInfo', defaultConfig.store_id)

      this.store = store
    },

    onResetFilter () {
      this.queryParams = {
        Limit: 20
      }
    }
  }
})
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: #ffffff;
}

.list {
  padding: 0.3rem;
  .item {
    border-radius: 0.1rem;
    box-shadow: 0 0 0.25rem rgba(150, 150, 150, 0.3);
    margin-bottom: 0.3rem;
    background: #ffffff;
    padding: 0.3rem 0.18rem;
    .top {
      font-size: 0.28rem;
      color: #333333;
      line-height: 0.44rem;
      display: flex;
      justify-content: space-between;
      .state {
        font-size: 0.24rem;
      }
    }

    .info-box {
      padding-top: 0.2rem;
      padding-bottom: 0.4rem;
      border-bottom: 1px solid #ebebeb;
      position: relative;
      padding-left: 1.98rem;
      .img {
        width: 1.78rem;
        height: 1.15rem;
        border-radius: 0.08rem;
        overflow: hidden;
        position: absolute;
        top: 0.2rem;
        left: 0;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .info {
        font-size: 0.24rem;
        position: relative;
        height: 1.15rem;
        .name {
          line-height: 0.6rem;
          font-size: 0.3rem;
        }
        .time {
          line-height: 0.44rem;
        }
        .combo {
          position: absolute;
          bottom: 0;
          right: 0;
          line-height: 0.6rem;
          line-height: 0.4rem;
        }
      }
    }

    .price-action {
      padding-top: 0.28rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price {
        font-size: 0.36rem;
      }
      .remark {
        font-size: 0.24rem;
        width: 4rem;
      }
    }
  }
}
.filter-btn {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999;
}
.filter-item {
  padding: 0.1rem;
  .item-title {
    margin-top: 20px;
  }
  .item-form {
    height: 0.85rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dcdcdc;
  }

  .btns {
    padding-top: 20px;
    .btn {
      width: 100%;
      height: 0.7rem;
      text-align: center;
      border: 1px solid #dcdcdc;
      border-radius: 5px;
      margin-top: 10px;
      line-height: 0.7rem;
      &.ok {
        border-color: #1890ff;
        background: #1890ff;
        color: #fff;
      }
      &.cancle {
        border-color: #ff7875;
        background: #ff7875;
        color: #fff;
      }
      &.reset {
        border-color: #ff7875;
      }
    }
  }
}
.sex {
  font-size: 12px;
  color: #ccc;
  &.man {
    color: rgb(66, 48, 228);
  }
  &.feman {
    color: rgb(228, 48, 48);
  }
}
</style>
